<template>
  <div class="app-container">
    <!-- 查询区域 -->
    <div>
      <el-form :model="queryParams" layout="inline" :inline="true">
        <el-row>
          <el-col :md="6" :sm="24" style="width:70%">
            <el-button type="primary" @click="selectDataListButton()">搜索</el-button>
            <el-button type="primary" @click="primaryRest()">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- 按钮区 -->
    <el-row :gutter="20" style="margin:9px 2px">
      <el-button type="success" icon="el-icon-add" size="min" @click="addButton">新增</el-button>
      <el-button type="success" icon="el-icon-add" size="min" @click="editButton">编辑</el-button>
      <el-button type="success" icon="el-icon-add" size="min" @click="delectButton" disabled>删除</el-button>
      <el-button
        type="success"
        icon="el-icon-add"
        size="min"
        @click="selectButton(this.id)"
        disabled
      >查看</el-button>
    </el-row>

    <!--     列表区域   -->
    <el-table
      :data="dataColumnList"
      row-key="id"
      border
      :tree-props="{children: 'xunYearPlanRespVOList', hasChildren: 'hasChildren'}"
    >
      <el-table-column label="序号" align="center" width="65">
        <template slot-scope="scope">
          <el-radio
            :label="scope.$index"
            v-model="radio"
            @change.native="getCurrentRow(scope.row)"
            :disabled="scope.row.yearId==null?true:false"
          />
        </template>
      </el-table-column>
      <el-table-column label="年份" align="center" prop="year" />
      <el-table-column label="归属类目" align="center" prop="attributionCategory" />
      <el-table-column label="计划名称" align="center" prop="planName" />
      <el-table-column label="目标金额" align="center" prop="targetMoney" />
      <el-table-column label="本金" align="center" prop="capital" />
      <el-table-column label="预计总金" align="center" prop="preSumMoney" />
      <!-- <el-table-column label="状态" align="center" prop="status" :formatter="productStateEnum" /> -->
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="selectButton(scope.row)"
            disabled
          >查看</el-button>
        </template>
      </el-table-column>
    </el-table>

  

    <addEdit ref="addEditFrom" />
  </div>
</template>


<script>
import addEdit from "./modules/addEdityearplan";
import {
  selectyearplanByIdApi,
  deleteyearplanByIdApi,
  edityearplanApi,
  addyearplanApi,
  listyearplanApi,
  groupByYearApi
} from "@/api/work/plan/yearplan.js";

export default {
  components: {
    addEdit
  },
  data() {
    return {
      // 选中数组
      radio: null,
      statusOption: [],
      ids: null,
      // 查询参数
      queryParams: {
        current: 1,
        pageSize: 10,
        total: 1
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {},
      optFrom: {},
      dataColumnList: [],
      year: null
    };
  },
  created() {
    this.selectDataListButton();
    this.initDict();
  },
  methods: {
    selectDataListButton() {
      groupByYearApi().then(res => {
        this.dataColumnList = res.result;
      });
    },
    getCurrentRow(row) {
      // 获取选中数据   row表示选中这一行的数据，可以从里面提取所需要的值
      this.ids = row.id;
      this.optFrom = row;
      this.year = row.year;
    },
    addButton() {
      this.$refs.addEditFrom.show(null, 1, null);
    },
    editButton() {
      if (this.ids == null) return this.$message.error("请选择有效的一列");
      this.$refs.addEditFrom.show(this.ids, 2, this.year);
    },
    delectButton() {
      if (this.ids == null) return this.$message.error("请选择有效的一列");
    },
    selectButton() {
      if (this.ids == null) return this.$message.error("请选择有效的一列");
    },
    primaryRest() {
      this.$refs["queryParams"].resetFields();
      this.selectDataListButton();
    },
    initDict() {
      this.selectDictItemSyn("common", "common_status").then(
        response => {
          this.statusOption = response.result;
        }
      );
    },
    productStateEnum(row, column) {
      return this.selectDictLabel(this.statusOption, row.status);
    }
  }
};
</script>



